import { ALLOW_MULTIPLE_INTEGRATED_RETURNS } from '_common/constants/common';
import i18nService from '_common/services/i18nService';

export const validateOnSpecialCharacters = (
  _,
  value: string,
  callback: (error?: Error) => void
) => {
  const error = new Error(i18nService.t('wl:validationSpecError'));
  if (/[<#&]/.test(value)) {
    return callback(error);
  }
  return callback();
};

export const validateOnSpecialCharactersAddress = (
  _,
  value: string,
  callback: (error?: Error) => void
) => {
  const error = new Error(i18nService.t('wl:validationSpecError'));
  if (/[<&]/.test(value)) {
    return callback(error);
  }
  return callback();
};

export const convertFromAddress = ({
  phoneNumber,
  email,
  fullName,
  addressLine1,
  addressLine2,
  city,
  state,
}) => ({
  phoneNumber,
  email,
  name: fullName,
  line1: addressLine1,
  line2: addressLine2,
  city,
  state,
});

export const convertReturns = ({
  purchaseDate,
  returnItems,
  returnReason,
  confirmTerms,
  reasonComments,
  returnsAuthorisationNumber,
  isIntegrated,
}) => ({
  purchaseDate,
  returnReason,
  confirmTerms,
  reasonComments,
  returnsAuthorisationNumber,
  ...(isIntegrated && {
    returnItems: `${returnItems}`,
  }),
});

const convertAddressForMap = ({ line1, line2, town, area }) => ({
  addressLine1: line1,
  addressLine2: line2,
  city: town,
  state: area,
});

const checkForReturnedProducts = !ALLOW_MULTIPLE_INTEGRATED_RETURNS;

const assetsForStoresEnabled = true;

const checkForPrintInStoreOptionsOverrides = () => ({
  printIconVisible: true,
  printInStoreFilterVisible: true,
});

export default {
  convertFromAddress,
  convertAddressForMap,
  convertReturns,
  checkForReturnedProducts,
  assetsForStoresEnabled,
  checkForPrintInStoreOptionsOverrides,
};
