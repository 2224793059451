import starActive from './images/star-active.svg';
import starDisabled from './images/star-inactive.svg';
import storeIcon from './images/3rdparty-pin.svg';
import storeActiveIcon from './images/3rdparty-pin-selected.svg';
import postOfficeIcon from './images/post-office.svg';
import postOfficeActiveIcon from './images/post-office-selected.svg';
import markerIcon from './images/store.svg';
import activeMarkerIcon from './images/store-selected.svg';
import './fonts.css';

const GoogleMapIcons = {
  starActive,
  starDisabled,
  storeIcon,
  storeActiveIcon,
  postOfficeIcon,
  postOfficeActiveIcon,
  markerIcon,
  activeMarkerIcon,
};

export { starActive, starDisabled, GoogleMapIcons };
