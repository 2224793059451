import logoImage from 'assets/images/starlinksLogo.png';
import basicTheme from '_common/constants/theme';
import { PAYMENT_TYPES } from '_common/constants/common';

export const TRACKING_EVENT_TYPES = {
  ORDER_RETURN_CREATED: 'ORDER_RETURN_CREATED',
  PARCEL_REACHED_STORE: 'PARCEL_REACHED_STORE',
  CARRIER_COLLECTED: 'CARRIER_COLLECTED',
  BACK_AT_WAREHOUSE: 'BACK_AT_WAREHOUSE',
};
export const PRODUCT_NAME = 'STARLINKS_RETURNS_PORTAL';
export const defaultTheme = {
  ...basicTheme,
  assets: { logoImage },
  config: {
    css: {
      accentColor: '#000',
    },
    consumerText: {},
    termsAndConditionsLink: '',
  },
};
export const DEFAULT_PAYMENT_TYPE = PAYMENT_TYPES.MERCHANT_PAID;
export const IS_INTEGRATED_JOURNEY_AVAILABLE = true;
export const DEFAULT_CURRENCY_NAME = 'SR';
export const GOOGLE_MAP_OPTIONS: google.maps.MapOptions = {
  styles: [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      featureType: 'administrative.country',
      elementType: 'labels.text',
      stylers: [
        {
          color: '#00714b',
        },
        {
          weight: 6.5,
        },
      ],
    },
    {
      featureType: 'administrative.country',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#00714b',
        },
        {
          weight: 5,
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd',
        },
      ],
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#00714b',
        },
      ],
    },
    {
      featureType: 'administrative.neighborhood',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#00714b',
        },
      ],
    },
    {
      featureType: 'administrative.province',
      elementType: 'labels.icon',
      stylers: [
        {
          color: '#00714b',
        },
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#00714b',
        },
        {
          lightness: 50,
        },
        {
          visibility: 'simplified',
        },
        {
          weight: 4.5,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.icon',
      stylers: [
        {
          color: '#00714b',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#00714b',
        },
        {
          weight: 4.5,
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#c9c9c9',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
  ],
};
