import { starlinksLogo, phoneIcon, printerIcon } from 'assets';
import {
  validateOnSpecialCharacters,
  validateOnSpecialCharactersAddress,
} from '_common/whitelabelConfig/STARLINKS/_utils';
import {
  GOOGLE_MAP_OPTIONS,
  TRACKING_EVENT_TYPES,
} from '_common/whitelabelConfig/STARLINKS/_constants';
import {
  GoogleMapIcons,
  starActive,
  starDisabled,
} from '_common/whitelabelConfig/STARLINKS/_assets';
import { IWhiteLabelUi } from 'types/whitelabelTypes';
import i18nService from '_common/services/i18nService';
import {
  EDETAILS_ADDRESS_FIELD,
  EAPP_LOCALES,
  EPrintOptions,
} from 'types/core';
import { validatePhoneNumber } from '_common/utils/validationUtils';
import { ALLOWED_DATEPICKER_FORMATS } from '_common/constants/date';
import { DEFAULT_SPECIAL_CHARS_REGEX } from '_common/constants/regex';

const config: IWhiteLabelUi = {
  common: {
    carrierLogo: {
      src: starlinksLogo,
      alt: 'Easy returns by Starlinks',
      dimensions: {
        desktop: {
          width: 78,
          height: 58,
        },
        mobile: {
          width: 78,
          height: 58,
        },
      },
    },
    countryCode: 'SA',
    availableLanguages: [EAPP_LOCALES.AR, EAPP_LOCALES.EN],
    isPrinterOptionDisabled: true,
    header: {
      showCarrierLogo: false,
      centeredLogo: true,
    },
    footer: {
      isHidden: false,
      showCarrierLogo: true,
      logoStacked: false,
      termsAndConditions: {
        href:
          'https://connect-starlinks.com/docs/CONNECT_Terms_and_Conditions.pdf',
        label: 'footerTermsLabel', // withTranslation
      },
      privacy: {
        href: 'https://connect-starlinks.com/docs/CONNECT_Privacy_Policy.pdf',
        label: 'footerPrivacyLabel', // withTranslation
      },
    },
    defaultMapCenter: {
      lat: 24.732367,
      lng: 46.670154,
    },
    isConsumerTextEnabled: (isIntegrated: boolean) => isIntegrated,
  },
  pages: {
    printOption: {
      [EPrintOptions.PAPERLESS]: {
        title: 'printPaperlessTitle', // withTranslation
        nearestLocationText: 'printPaperlessLocation', // withTranslation
        imageSize: 60,
        icon: phoneIcon,
        points: [
          { icon: phoneIcon, text: 'printPaperlessPoint1' }, // withTranslation
          {
            icon: phoneIcon,
            text: 'printPaperlessPoint2', // withTranslation
            includes: [{ text: 'printPaperlessPoint2includes1' }], // withTranslation
          },
        ],
      },
      [EPrintOptions.PRINT_OWN]: {
        title: 'printOwnTitle', // withTranslation
        nearestLocationText: 'printOwnLocation', // withTranslation
        imageSize: 100,
        icon: printerIcon,
        points: [
          { icon: printerIcon, text: 'printOwnPoint1' }, // withTranslation
          {
            icon: printerIcon,
            text: 'printOwnPoint2', // withTranslation
            includes: [
              { text: 'printOwnPoint2includes1' }, // withTranslation
            ],
          },
        ],
      },
    },
    start: {
      titleText: 'titleText', // withTranslation
      returnIsFreeText: 'startFree', // withTranslation
      ranPlaceholder: 'startPlaceholder', // withTranslation
      costText: 'startCost', // withTranslation
      getOrderIdRegex: () => DEFAULT_SPECIAL_CHARS_REGEX,
    },
    details: {
      dateFormates: ALLOWED_DATEPICKER_FORMATS,
      orderedFiledNames: [
        [EDETAILS_ADDRESS_FIELD.PHONE],
        [EDETAILS_ADDRESS_FIELD.ADDRESS1, EDETAILS_ADDRESS_FIELD.ADDRESS2],
        [EDETAILS_ADDRESS_FIELD.CITY, EDETAILS_ADDRESS_FIELD.STATE],
        [EDETAILS_ADDRESS_FIELD.ZIP],
      ],
      getStateLabel: item => `${item.value} - ${item.label}`,
      textValues: {
        purchaseDateLabel: 'detailsPurchaseDate', // withTranslation
        purchaseDatePlaceholder: 'detailsPurchaseDatePlaceholder', // withTranslation
        purchaseDateRequiredErr: 'detailsPurchaseDateRequiredErr', // withTranslation
        returnReasonRequiredErr: 'detailsReturnReasonRequiredErr', // withTranslation
        address1Label: 'address1Label', // withTranslation
        address2Label: 'address2Label', // withTranslation
        address1Placeholder: 'address1Placeholder', // withTranslation
        address2Placeholder: 'address2Placeholder', // withTranslation
        cityLabel: 'detailsCity', // withTranslation
        cityPlaceholder: 'detailsCityPlaceholder', // withTranslation
        areaLabel: 'detailsArea', // withTranslation
        areaPlaceholder: 'detailsAreaPlaceholder', // withTranslation
        isZipVisible: false,
        tcUrl:
          'https://connect-starlinks.com/docs/CONNECT_Terms_and_Conditions.pdf',
        isAreaFreeText: true,
        countryStates: [],
        addressInvalidMsg: i18nService.t('wl:detailsAdressError'), // withTranslation
      },
      rules: {
        fullName: [
          {
            required: true,
            message: i18nService.t('wl:detailsFullnameReq'), // withTranslation
          },
          {
            max: 32,
            message: i18nService.t('wl:detailsFullnameErrAlt'), // withTranslation
          },
          {
            validator: (
              rule,
              value: string,
              callback: (error?: Error) => void
            ) => {
              validateOnSpecialCharacters(rule, value, callback);
            },
          },
        ],
        zipCode: [],
        addressLine1: [
          {
            required: true,
            message: i18nService.t('wl:detailsAddressReq'), // withTranslation
          },
          {
            max: 50,
            message: i18nService.t('wl:detailsAddressErrAlt'), // withTranslation
          },
          {
            validator: (
              rule,
              value: string,
              callback: (error?: Error) => void
            ) => {
              validateOnSpecialCharactersAddress(rule, value, callback);
            },
          },
        ],
        addressLine2: [
          {
            max: 50,
            message: i18nService.t('wl:detailsAddress2ErrAlt'), // withTranslation
          },
          {
            validator: (
              rule,
              value: string,
              callback: (error?: Error) => void
            ) => {
              validateOnSpecialCharactersAddress(rule, value, callback);
            },
          },
        ],
        area: [
          {
            required: true,
            message: i18nService.t('wl:detailsAreaReq'), // withTranslation
          },
        ],
        city: [
          {
            required: true,
            message: i18nService.t('wl:detailsCityReq'), // withTranslation
          },
          {
            max: 25,
            message: i18nService.t('wl:detailsCityErrAlt'), // withTranslation
          },
          {
            validator: (
              rule,
              value: string,
              callback: (error?: Error) => void
            ) => {
              validateOnSpecialCharacters(rule, value, callback);
            },
          },
        ],
        phoneNumber: [
          {
            required: true,
            whitespace: true,
            message: i18nService.t('details:mobileError'),
          },
          {
            validator: (
              rule,
              value: string,
              callback: (error?: Error) => void
            ) => {
              validateOnSpecialCharacters(rule, value, callback);
            },
          },
          {
            validator: validatePhoneNumber,
          },
        ],
      },
      noReasonsText: null,
      noActiveReasonsAllowed: true,
      integratedReasonCommentsEnabled: true,
      reasonCommentsRequiredText: 'detailsCommentError', // withTranslation
      wrapReasonCodeText: false,
    },
    tracking: {
      trackersList: [
        {
          type: TRACKING_EVENT_TYPES.ORDER_RETURN_CREATED,
          descriptionKey: 'wl:trackingCreated',
        },
        {
          type: TRACKING_EVENT_TYPES.PARCEL_REACHED_STORE,
          descriptionKey: 'wl:trackingReached',
        },
        {
          type: TRACKING_EVENT_TYPES.CARRIER_COLLECTED,
          descriptionKey: 'wl:trackingCollected',
        },
        {
          type: TRACKING_EVENT_TYPES.BACK_AT_WAREHOUSE,
          descriptionKey: 'wl:trackingBack',
        },
      ],
    },
    success: {
      textValues: {
        successSearchPlaceholder: 'successPlaceholder', // withTranslation
      },
      distanceUnit: 'mi',
      storeLogosEnabled: false,
      availableTypeOptions: ['PRINT_IN_STORE', 'ALL_LOCATIONS'],
      returnSubtitlePaperless: 'success:returnSubtitlePaperless',
      returnSubtitleOwnLabel: () => 'success:returnSubtitleOwnLabel',
      pdfDownloadEnabled: false,
    },
    ratings: {
      config: {
        title: 'ratingsTitle', // withTranslation
        subTitle: 'ratingsSubtitle', // withTranslation
        icons: {
          starActive,
          starDisabled,
        },
      },
    },
    locate: {
      GoogleMapIcons,
      GoogleMapOptions: GOOGLE_MAP_OPTIONS,
    },
  },
};

export default config;
